import { extendTheme, ThemeConfig } from "@chakra-ui/react";

import { colors } from "./colors";
import components from "./components";
import { fonts } from "./fonts";
import { textStyles } from "./textStyles";
import { withProse } from "@nikolovlazar/chakra-ui-prose";

const config: ThemeConfig = {
  // initialColorMode: "dark",
};

const theme = extendTheme(
  {
    fonts,
    textStyles,
    colors,
    components,
    config,
  },
  withProse()
);

export default theme;
