import {
  FormControl,
  FormLabel,
  Button,
  FormErrorMessage,
  HStack,
  Textarea,
  VStack,
  RadioGroup,
  Radio,
  Stack,
  useToast,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import countryList from "country-list";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import formSchema, {
  desiredCompletionDateOptions,
  estimatedBudgetOptions,
  hearAboutUsOptions,
  natureOfEnquiryOptions,
  typeOfOrganisationOptions,
} from "../../../consts/formSchema";
import FormInput from "./FormInput";
import FormDropdown from "./FormDropdown";
import axios from "axios";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { textStyles } from "../../../theme/textStyles";
import Link from "next/link";
import styled from "@emotion/styled";

// TODO: fix `any` type
export const FormRow = (props: any) => (
  <Stack
    {...props}
    spacing={{ base: "20px", md: "20px" }}
    paddingBottom={{ base: "20px", md: 0 }}
    width="100%"
    direction={{ base: "column", md: "row" }}
    alignItems={{ base: "center", md: "flex-end" }}
  />
);

const defaultRequiresBlockchainMessage =
  "Labrys is only taking blockchain related enquires at this time. If your enquiry does not relate to blockchain, we will not be able to process it.";

const defaultCareersMessage =
  "If you are wanting to enquire about careers at Labrys, head over to Labrys'";

function Form({
  requiresBlockchainMessage = defaultRequiresBlockchainMessage,
  careersMessage = defaultCareersMessage,
}) {
  const methods = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });

  const {
    formState: { errors },
    watch,
    setValue,
    reset,
    handleSubmit,
  } = methods;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const toast = useToast();

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = async (data) => {
    setIsSubmitting(true);

    try {
      await axios.post("/api/forms/contact", data);

      reset();
      toast({
        status: "success",
        duration: 9000,
        isClosable: true,
        render: () => (
          <HStack backgroundColor="yellow" padding="15px" rounded="sm">
            <CheckCircleIcon padding="10px" w={10} h={10} />
            <VStack alignItems="flex-start" spacing={0}>
              <Text {...textStyles.mono}>Enquiry Submitted.</Text>
              <Text>We&apos;ll be in touch.</Text>
            </VStack>
          </HStack>
        ),
      });
    } catch {
      toast({
        status: "error",
        duration: 9000,
        isClosable: true,
        render: () => (
          <HStack backgroundColor="red" padding="15px" rounded="sm">
            <WarningIcon padding="10px" w={10} h={10} />
            <VStack alignItems="flex-start" spacing={0}>
              <Text {...textStyles.mono}>Something Went Wrong.</Text>
              <Text>Please try again later.</Text>
            </VStack>
          </HStack>
        ),
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const [requiresBlockchain, setRequiresBlockchain] =
    useState<"yes" | "no" | "">("");

  const doesntRequireBlockchain = requiresBlockchain === "no";

  const natureOfEnquiry = watch("natureOfEnquiry");
  const PressOrOther = ["Press", "Other"].includes(natureOfEnquiry);
  const isCareers = ["Careers"].includes(natureOfEnquiry);

  const [disableSubmit, setDisableSubmit] = useState(true);

  const onHCaptchaVerify = (token: string) => {
    setValue("hCaptchaToken", token);
    setDisableSubmit(false);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} id="contact-form" noValidate>
        <VStack spacing={{ base: 0, md: 10 }}>
          <FormRow>
            <FormControl
              id="requiresBlockchain"
              isRequired
              isInvalid={doesntRequireBlockchain}
              as="fieldset"
            >
              <FormLabel as="legend">
                Does your enquiry relate to blockchain?
              </FormLabel>

              <RadioGroup
                value={requiresBlockchain}
                onChange={(value: "yes" | "no") => setRequiresBlockchain(value)}
              >
                <HStack spacing="24px">
                  <Radio value="yes">Yes</Radio>
                  <Radio value="no">No</Radio>
                </HStack>
              </RadioGroup>

              <FormErrorMessage>{requiresBlockchainMessage}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={isCareers}>
              <FormDropdown
                label="Nature of Enquiry"
                name="natureOfEnquiry"
                options={natureOfEnquiryOptions}
                isDisabled={doesntRequireBlockchain}
              />

              <FormErrorMessage>
                <Text>
                  {careersMessage}{" "}
                  <StyledLink>
                    <Link href="https://jobs.lever.co/labrys">
                      careers page.
                    </Link>
                  </StyledLink>
                </Text>
              </FormErrorMessage>
            </FormControl>
          </FormRow>

          <FormRow>
            <FormInput
              label="First Name"
              name="firstName"
              isDisabled={
                doesntRequireBlockchain || natureOfEnquiry === "Careers"
              }
            />
            <FormInput
              label="Last Name"
              name="lastName"
              isDisabled={
                doesntRequireBlockchain || natureOfEnquiry === "Careers"
              }
            />
          </FormRow>

          <FormRow>
            <FormInput
              label="Phone Number"
              name="phoneNumber"
              isDisabled={
                doesntRequireBlockchain || natureOfEnquiry === "Careers"
              }
            />
            <FormInput
              label="Email Address"
              name="emailAddress"
              isDisabled={
                doesntRequireBlockchain || natureOfEnquiry === "Careers"
              }
            />
          </FormRow>

          <FormRow>
            <FormInput
              label="Company Name"
              name="companyName"
              isDisabled={
                doesntRequireBlockchain || natureOfEnquiry === "Careers"
              }
            />
            <FormInput
              label="Website URL"
              name="websiteUrl"
              isDisabled={
                doesntRequireBlockchain || natureOfEnquiry === "Careers"
              }
            />
          </FormRow>

          <FormRow>
            <FormInput
              label="Job Title"
              name="jobTitle"
              isDisabled={
                doesntRequireBlockchain || natureOfEnquiry === "Careers"
              }
            />

            <FormDropdown
              label="Country"
              name="country"
              options={countryList.getNames()}
              isDisabled={
                doesntRequireBlockchain || natureOfEnquiry === "Careers"
              }
            />
          </FormRow>

          <FormRow>
            <FormDropdown
              label="Estimated Budget"
              name="estimatedBudget"
              options={
                natureOfEnquiry === "Blockchain Consulting"
                  ? estimatedBudgetOptions
                  : estimatedBudgetOptions.slice(1)
              }
              isDisabled={
                doesntRequireBlockchain ||
                PressOrOther ||
                natureOfEnquiry === "Careers"
              }
            />

            <FormDropdown
              label="How did you find out about us?"
              name="hearAboutUs"
              options={hearAboutUsOptions}
              isDisabled={
                doesntRequireBlockchain || natureOfEnquiry === "Careers"
              }
            />
          </FormRow>

          <FormRow>
            <FormDropdown
              name="desiredCompletionDate"
              label="Desired Completion Date"
              options={desiredCompletionDateOptions}
              isDisabled={
                doesntRequireBlockchain || natureOfEnquiry === "Careers"
              }
            />

            <FormDropdown
              name="typeOfOrganisation"
              label="Type of Organisation"
              options={typeOfOrganisationOptions}
              isDisabled={
                doesntRequireBlockchain || natureOfEnquiry === "Careers"
              }
            />
          </FormRow>

          <FormControl
            isRequired
            id="customMessage"
            isDisabled={
              doesntRequireBlockchain || natureOfEnquiry === "Careers"
            }
          >
            <FormLabel>Custom Message</FormLabel>
            <Textarea
              placeholder="Enter your message here"
              {...methods.register("customMessage")}
              rounded="none"
            />
            <FormErrorMessage>{errors.customMessage?.message}</FormErrorMessage>
          </FormControl>

          <Stack justifyContent="center">
            {process.env.NEXT_PUBLIC_HCAPTCHA_SITE_KEY && (
              <HCaptcha
                sitekey={process.env.NEXT_PUBLIC_HCAPTCHA_SITE_KEY}
                onVerify={onHCaptchaVerify}
              />
            )}
          </Stack>

          <FormRow>
            <Button
              mt={4}
              type="submit"
              variant="outline"
              borderColor="black"
              isDisabled={
                doesntRequireBlockchain || disableSubmit || isSubmitting
              }
              isLoading={isSubmitting}
            >
              Submit
            </Button>
          </FormRow>
        </VStack>
      </form>
    </FormProvider>
  );
}

export default Form;

const StyledLink = styled.a`
  text-decoration: underline;
  :hover {
    cursor: pointer;
  }
`;
