import Button from "./Button";
import FormLabel from "./FormLabel";
import Input from "./Input";
import Select from "./Select";
import Textarea from "./Textarea";
import Container from "./Container";
import Heading from "./Heading";

export default {
  Button,
  FormLabel,
  Input,
  Select,
  Textarea,
  Container,
  Heading,
};
