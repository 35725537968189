import { z } from "zod";

export const natureOfEnquiryOptions = [
  "Proof of Concept Development",
  "Enterprise Blockchain Development",
  "Smart Contract Development",
  "Blockchain Consulting",
  "Minimum Viable Product Development",
  "NFT Development",
  "Careers",
  "Press",
  "Other",
] as const;

export const typeOfOrganisationOptions = [
  "Corporate Enterprise",
  "Government",
  "DAO",
  "Blockchain Organisation",
  "Startup Project",
  "Individual",
  "Press",
] as const;

export const estimatedBudgetOptions = [
  "$0-$50,000.00 AUD",
  "$50,000.00-$100,000.00 AUD",
  "$100,000.00-$200,000.00 AUD",
  "$200,000.00-$500,000.00 AUD",
  "$500,000.00+ AUD",
] as const;

export const hearAboutUsOptions = [
  "GoodFirms/Clutch",
  "Search Engine",
  "Press Coverage",
  "Online Ads",
  "Events",
  "Networking",
  "Social Media",
  "Word of Mouth",
  "Other",
] as const;

export const desiredCompletionDateOptions = [
  "Less than 1 month",
  "1 - 2 Months",
  "2 - 4 Months",
  "4 - 8 Months",
  "1 Year+",
] as const;

const FormSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  phoneNumber: z.string(),
  emailAddress: z.string().email(),
  companyName: z.string(),
  websiteUrl: z.string().optional(),
  jobTitle: z.string(),
  country: z.string(),
  natureOfEnquiry: z.enum([...natureOfEnquiryOptions, ""] as const),
  typeOfOrganisation: z.enum([...typeOfOrganisationOptions, ""] as const),
  estimatedBudget: z.enum([...estimatedBudgetOptions, ""] as const),
  hearAboutUs: z.enum([...hearAboutUsOptions, ""] as const),
  desiredCompletionDate: z.enum([...desiredCompletionDateOptions, ""] as const),
  customMessage: z.string(),
  hCaptchaToken: z.string(),
});

export default FormSchema;
