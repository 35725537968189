import React from "react";

import { difference } from "lodash";
import { natureOfEnquiryOptions } from "../../../consts/formSchema";
import { CTANatureOfEnquiry, useCTAStore } from "./store";

import {
  Stack,
  Heading,
  Select,
  Collapse,
  useBreakpointValue,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
import EnquiryOption, { EnquiryType } from "./EnquiryOption";
import { Maybe } from "../../../.tina/__generated__/types";

const enquiryTypeDetails: EnquiryType[] = [
  {
    label: "Enterprise Blockchain",
    value: "Enterprise Blockchain Development",
    color: "#02FFA7",
    icon: "/icons/cube.svg",
  },
  {
    label: "Smart Contracts",
    value: "Smart Contract Development",
    color: "#70FFE0",
    icon: "/icons/eth.svg",
  },
  {
    label: "Blockchain Consulting",
    value: "Blockchain Consulting",
    color: "#D8FF00",
    icon: "/icons/cent.svg",
  },
  {
    label: "Minimum Viable Product",
    value: "Minimum Viable Product Development",
    color: "#5BFFA6",
    icon: "/icons/venn.svg",
  },
];

const somethingElseOptions = difference(
  natureOfEnquiryOptions,
  enquiryTypeDetails.map((v) => v.value)
);

interface NatureOfEnquiryProps {
  onChange: () => void | null;
  value: CTANatureOfEnquiry;
  onBlur: () => void | null;
  heading?: Maybe<string>;
}

/**
 * Form element for visually selecting the nature of the enquiry.
 * @returns {React.ReactElement}
 */
function NatureOfEnquiry({
  onChange,
  value: natureOfEnquiry,
  onBlur,
  heading,
}: NatureOfEnquiryProps): React.ReactElement {
  const stackCss = useBreakpointValue({
    base: undefined,
    lg: css`
      row-gap: 1rem;
    `,
  });

  return (
    <>
      <Heading
        textAlign="center"
        fontSize={{ base: "lg", sm: "xl", md: "2xl", lg: "3xl" }}
      >
        {heading}
      </Heading>
      <Stack
        direction={{ base: "column", lg: "row" }}
        spacing={{
          base: "2",
          sm: "3",
          md: "4",
          lg: "4",
          xl: "8",
        }}
        flexWrap="wrap"
        justifyContent="center"
        css={stackCss}
      >
        {enquiryTypeDetails.map((service) => (
          <EnquiryOption
            {...service}
            key={service.value}
            onChange={onChange}
            selected={service.value === natureOfEnquiry}
          />
        ))}
        <EnquiryOption
          label="Something else"
          color="#FFFF03"
          icon="/icons/plus.svg"
          value="somethingElse"
          onChange={onChange}
          selected={
            natureOfEnquiry === "somethingElse" ||
            somethingElseOptions.includes(natureOfEnquiry)
          }
        />
      </Stack>
      <Collapse
        in={
          natureOfEnquiry === "somethingElse" ||
          somethingElseOptions.includes(natureOfEnquiry)
        }
        animateOpacity
      >
        <Stack w="100%" alignItems="center">
          <Select
            w="sm"
            placeholder="Select option"
            py="2"
            rounded="none"
            value={
              somethingElseOptions.includes(natureOfEnquiry)
                ? natureOfEnquiry
                : undefined
            }
            onChange={onChange}
            onBlur={onBlur}
          >
            {somethingElseOptions.map((value) => (
              <option value={value} key={value}>
                {value}
              </option>
            ))}
          </Select>
        </Stack>
      </Collapse>
    </>
  );
}

export default NatureOfEnquiry;
