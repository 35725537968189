import type { SystemStyleObject } from "@chakra-ui/theme-tools";

const baseStyle: SystemStyleObject = {
  borderRadius: "0",
  fontStretch: "18px/22px",
  padding: "0 40px",
};

export default {
  baseStyle,
};
