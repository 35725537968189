import { z } from "zod";
import create from "zustand";
import formSchema from "../../../consts/formSchema";

export type CTANatureOfEnquiry =
  | Required<z.infer<typeof formSchema>>["natureOfEnquiry"]
  | "somethingElse";

type CTAFormStore = {
  page: number;
  numPages: number;
  nextPage: () => void;
  previousPage: () => void;
  resetPages: () => void;
};

export const useCTAStore = create<CTAFormStore>((set) => ({
  page: 0,
  numPages: 4,
  nextPage: () => set((state) => ({ page: state.page + 1 })),
  previousPage: () => set((state) => ({ page: state.page - 1 })),
  resetPages: () => set(() => ({ page: 0 })),
}));
