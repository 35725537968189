import { Stack, Flex, HStack, Text, Image } from "@chakra-ui/react";
import React from "react";
import { textStyles } from "../../../theme/textStyles";

import { CTANatureOfEnquiry } from "./store";

export interface EnquiryType {
  label: string;
  value?: CTANatureOfEnquiry;
  color: string;
  icon: string;
  onChange?: (value: CTANatureOfEnquiry) => void;
  selected?: boolean;
}

const EnquiryOption = ({
  icon,
  label,
  color,
  value,
  onChange,
  selected,
}: EnquiryType) => {
  return (
    <Stack
      direction={{ base: "row-reverse", lg: "column" }}
      justifyContent="center"
      h={{ lg: "120px" }}
      w={{ base: "100%", lg: "120px" }}
      backgroundColor={color}
      padding="1.5"
      alignItems={{ base: "center", lg: "flex-start" }}
      outline={selected ? "2px black solid" : undefined}
      onClick={() => onChange && value && onChange(value)}
      cursor="pointer"
      _hover={{
        filter: "brightness(90%)",
      }}
      py={{ base: "4", lg: "1.5" }}
    >
      <Flex
        alignItems={{ base: "center", lg: "flex-start" }}
        flexGrow={{ base: 1, lg: undefined }}
      >
        <Text
          fontSize={{ base: "xs", sm: "sm", lg: "8px" }}
          {...textStyles.mono}
          maxWidth="70%"
          color="black"
        >
          {label}
        </Text>
      </Flex>
      <HStack
        justifyContent="center"
        px="4"
        flexGrow={{ base: 0, lg: 1 }}
        alignItems={{ base: "flex-start", lg: "center" }}
        h="100%"
        w={{ base: "4rem", sm: "5rem", lg: "100%" }}
      >
        <Image
          src={icon}
          alt={label}
          maxW={{ base: "50px", lg: "60px" }}
          maxH={{ base: "50px", lg: "60px" }}
        />
      </HStack>
    </Stack>
  );
};

export default EnquiryOption;
