import { Stack, Text, Button } from "@chakra-ui/react";
import React from "react";
import { VscArrowLeft, VscArrowRight } from "react-icons/vsc";
import { useCTAStore } from "./store";

const PaginationButtons = ({
  submitButton,
}: {
  submitButton: React.ReactNode;
}) => {
  const { nextPage, previousPage, numPages, page } = useCTAStore();

  return (
    <Stack direction="row" justify="center" spacing="8">
      {page !== 0 && (
        <Button
          onClick={() => previousPage()}
          variant="outline"
          borderColor="black"
        >
          <VscArrowLeft size="23px" />
          <Text ml="1em" textStyle="mono">
            Previous
          </Text>
        </Button>
      )}
      {page !== numPages - 1 && (
        <Button
          onClick={() => nextPage()}
          variant="outline"
          borderColor="black"
        >
          <Text mr="1em" textStyle="mono">
            Next
          </Text>
          <VscArrowRight size="23px" />
        </Button>
      )}
      {page === numPages - 1 && submitButton}
    </Stack>
  );
};

export default PaginationButtons;
