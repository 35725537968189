import { Box } from "@chakra-ui/layout";
import { useRouter } from "next/router";
import React from "react";
import CTAForm from "../contact/CTAForm";
import StickyTab from "../StickyTab/StickyTab";
import CookiesAcceptance from "./CookiesAcceptance";
import Footer from "./Footer";
import Header from "./Header";

function Layout({ data, children }: any) {
  const router = useRouter();
  return (
    <Box minHeight="100vh">
      <Header data={data?.header} />
      <StickyTab />
      {/* set minHeight to the screen height minus the header and footer */}
      <CookiesAcceptance />
      <Box minHeight="calc(100vh - 154px - 62px)">{children}</Box>
      {router.asPath !== "/contact" &&
        router.route !== "/404" &&
        data?.ctaForm && <CTAForm data={data?.ctaForm} />}
      <Footer data={data?.footer} />
    </Box>
  );
}

export default Layout;

/**
 * The global layout of the page, remember to add any props your
 * components have to here.
 */
export const layoutQueryFragment = `#graphql
  getGlobalDocument(relativePath: "index.json") {
    data {
      header {
        nav {
          href
          label
        }
      }
      footer {
        links {
          href
          label
        }
      }
      ctaForm {
        backgroundPattern
        headingPage1
        headingPage2
        headingPage3
        headingPage4
      }
      titlePrefix
    }
  }
`;
