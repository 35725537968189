import {
  Menu,
  MenuButton,
  Image,
  Link,
  MenuItem,
  MenuList,
  Text,
  HStack,
  Box,
  VStack,
  Button,
  Flex,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { MdOutlineClose, MdMenu } from "react-icons/md";

import { textStyles } from "../../theme/textStyles";

function MobileMenu({ nav }: { nav: any[] }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Flex
        bgColor={open ? "#FEFE05" : "#00FFD4"}
        height="50px"
        width="50px"
        alignItems="center"
        justifyContent="center"
        onClick={() => setOpen((v) => !v)}
      >
        {open ? (
          <MdOutlineClose style={{ height: "100%", width: "35px" }} />
        ) : (
          <MdMenu style={{ height: "100%", width: "35px" }} />
        )}
      </Flex>

      {open && (
        <VStack
          position="absolute"
          top="50px"
          right="0"
          backgroundColor="#00FFD4"
          p="4"
        >
          {nav.map((item, i) => (
            // <Box >
            <Link href={item.href} key={`${item.label}-${i}`}>
              <Button variant="ghost" p="6">
                <Text {...textStyles.mono} fontSize="lg">
                  {item.label}
                </Text>
              </Button>
            </Link>
            // </Box>
          ))}
        </VStack>
      )}
    </>
  );
}

export default MobileMenu;
