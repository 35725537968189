import { VStack, Heading } from "@chakra-ui/react";
import Link from "next/link";
import React from "react";

/**
 * A block of headings which link to external social
 * media sites.
 *
 */
const SocialMediaBlock = () => {
  return (
    <VStack
      bg="aqua.500"
      width="fit-content"
      align="flex-start"
      w={{ base: "100%", lg: "350px" }}
      p="10px"
      mt={{ base: "20px", md: "0" }}
    >
      <Link href="https://au.linkedin.com/company/labrys-io" passHref>
        <Heading size="lg" _hover={{ cursor: "pointer" }}>
          LinkedIn
        </Heading>
      </Link>
      <Link href="https://www.facebook.com/labrys.io" passHref>
        <Heading size="lg" _hover={{ cursor: "pointer" }}>
          Facebook
        </Heading>
      </Link>
      <Link href="https://www.instagram.com/labrys.io/?hl=en" passHref>
        <Heading size="lg" _hover={{ cursor: "pointer" }}>
          Instagram
        </Heading>
      </Link>
      <Link
        href="https://twitter.com/Labrys_io?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
        passHref
      >
        <Heading size="lg" _hover={{ cursor: "pointer" }}>
          Twitter
        </Heading>
      </Link>
    </VStack>
  );
};

export default SocialMediaBlock;
