import { Box, Text, useBreakpointValue } from "@chakra-ui/react";
import { m } from "framer-motion";
import React from "react";
import { textStyles } from "../../theme/textStyles";

function StickyTab() {
  const display = useBreakpointValue({ base: false, md: true });

  return (
    <Box right="0" height="100%" position="absolute" top="0" width="0px">
      {display && (
        <m.div
          style={{
            backgroundColor: "#02FFA6",
            position: "sticky",
            top: "50%",
            padding: "10px 25px",
            flexDirection: "column",
            height: "80px",
            width: "170px",
            fontSize: "21px",
            alignItems: "center",
            justifyContent: "center",
            zIndex: "10",
            display: "flex",
            translateX: "-120px",
            rotate: "-270deg",
            originX: "80px",
            color: "black",
          }}
        >
          <Text fontFamily={textStyles.mono.fontFamily}>Shape the</Text>
          <Text fontFamily={textStyles.mono.fontFamily}>NEW WORLD</Text>
        </m.div>
      )}
    </Box>
  );
}

export default StickyTab;
