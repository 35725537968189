import { Global } from "@emotion/react";

const Fonts = () => (
  <Global
    styles={`
      /* latin */
      @font-face {
        font-family: "GT America Expanded Black";
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url("/fonts/GT-America-Expanded-Black.otf") format("opentype");
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
          U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
          U+FEFF, U+FFFD;
      }
      
      /* latin */
      @font-face {
        font-family: "GT America Extended Regular";
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url("/fonts/GT-America-Extended-Regular.otf") format("opentype");
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
          U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
          U+FEFF, U+FFFD;
      }

      /* latin */
      @font-face {
        font-family: "GT America Mono";
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url("/fonts/GT-America-Mono-Medium.otf") format("opentype");
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
          U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
          U+FEFF, U+FFFD;
      }
      `}
  />
);

export default Fonts;
