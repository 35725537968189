import { SystemStyleObject } from "@chakra-ui/react";
import type { PartsStyleObject } from "@chakra-ui/theme-tools";
import { selectAnatomy as parts } from "@chakra-ui/anatomy";

const baseStyleField: SystemStyleObject = {
  borderColor: "black",
  borderRadius: "0",
};

const baseStyle: PartsStyleObject<typeof parts> = {
  field: baseStyleField,
};

export default { baseStyle };
