import {
  useViewportScroll,
  useTransform,
  domAnimation,
  LazyMotion,
  m,
} from "framer-motion";
import Link from "next/link";
import { Image } from "@chakra-ui/react";
import React from "react";

function CollapsingLogo() {
  const { scrollY } = useViewportScroll();

  // use the `useTransform` hook to cap the translation to 186 pixels
  const translateX = useTransform(scrollY, [62, 372], [0, 186]);

  return (
    <>
      <Link href="/" passHref>
        <Image
          src="/labrys-logo-master-top.png"
          alt="Labrys logo"
          height="62px"
          width="186px"
          cursor="pointer"
          position={scrollY ? "sticky" : "absolute"}
          top="0"
          zIndex="10"
        />
      </Link>

      <LazyMotion features={domAnimation}>
        <m.div
          style={{
            position: "sticky",
            top: "0",
            left: "0",
            translateX,
            zIndex: 9,
            marginBottom: "-124px",
            width: "186px",
          }}
        >
          <Link href="/" passHref>
            <Image
              src="/labrys-logo-master-bottom.png"
              alt="Labrys logo"
              height="62px"
              width="186px"
              cursor="pointer"
            />
          </Link>
        </m.div>
      </LazyMotion>
    </>
  );
}

export default CollapsingLogo;
