import { css, Global } from "@emotion/react";
import Link from "next/link";
import Script from "next/script";
import React, { useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import TagManager, { TagManagerArgs } from "react-gtm-module";

const tagManagerArgs: TagManagerArgs = {
  gtmId: "GTM-TQTZDL9",
};

/**
 * Gets the value for a a specific cookie.
 */
const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts && parts.length === 2) return parts.pop()!.split(";").shift();
};

/**
 * From GitHub Copilot
 *
 * Deletes all cookies except the labrys consent cookie
 */
const deleteAllCookies = () => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    if (name.trim() !== "labrys-cookie-consent") {
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }
  }
};

const CookiesAcceptance = () => {
  const [cookiesConsent, setCookiesConsent] = useState<string | undefined>();

  useEffect(() => {
    // if cookies have not been explicitly declined, initialize google tag manager
    if (cookiesConsent === "true") {
      TagManager.initialize(tagManagerArgs);
    } else {
      // if cookies have been explicitly declined, delete all cookies
      deleteAllCookies();
    }
  }, [cookiesConsent]);

  useEffect(() => {
    // set cookie consent to true if it is undefined
    setCookiesConsent(getCookie("labrys-cookie-consent") ?? "true");
  }, []);

  return (
    <>
      <CookieConsent
        enableDeclineButton
        location="bottom"
        buttonText="I accept"
        declineButtonText="I decline"
        containerClasses="cookie-accpt-container"
        buttonClasses="cookie-button"
        style={{
          background: "#5bffa7",
          color: "#000",
          borderTop: "1px solid #000",
          height: "10%",
          alignItems: "center",
        }}
        buttonStyle={{
          backgroundColor: "#FFFF00",
          fontSize: "16px",
          border: "1px solid #000",
        }}
        declineButtonStyle={{
          backgroundColor: "#00FFD3",
          fontSize: "16px",
          border: "1px solid #000",
          color: "#000",
        }}
        cookieName="labrys-cookie-consent"
        onAccept={() => setCookiesConsent("true")}
        onDecline={() => {
          setCookiesConsent("false");
          window.location.reload();
        }}
      >
        By using this site, you consent to the use of strictly necessary cookies
        in accordance with our{" "}
        <Link href="/privacy-policy">Privacy Policy</Link>.
      </CookieConsent>
      <Global
        styles={css`
          .cookie-accpt-container {
            @media only screen and (max-width: 600px) {
              height: 20% !important;
              width: 100%;
              justify-content: space-evenly;
            }
          }
          .cookie-button {
            margin-left: 20px !important;
          }
        `}
      />
    </>
  );
};

export default CookiesAcceptance;
