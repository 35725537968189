export const colors = {
  yellow: {
    "50": "#FFFFE5",
    "100": "#FFFFB8",
    "200": "#FFFF8A",
    "300": "#FFFF5C",
    "400": "#FFFF2E",
    "500": "#FFFF00",
    "600": "#CCCC00",
    "700": "#999900",
    "800": "#666600",
    "900": "#333300",
  },
  green: {
    "50": "#E5FFF6",
    "100": "#B8FFE6",
    "200": "#8AFFD7",
    "300": "#5CFFC7",
    "400": "#2EFFB7",
    "500": "#00FFA7",
    "600": "#00CC86",
    "700": "#009964",
    "800": "#006643",
    "900": "#003321",
  },
  aqua: {
    "50": "#E5FFFB",
    "100": "#B8FFF3",
    "200": "#8AFFEB",
    "300": "#5CFFE3",
    "400": "#2EFFDB",
    "500": "#00FFD3",
    "600": "#00CCA9",
    "700": "#00997F",
    "800": "#006654",
    "900": "#00332A",
  },
  turqoise: {
    "50": "#E5FFF9",
    "100": "#B8FFEF",
    "200": "#8AFFE5",
    "300": "#5CFFDB",
    "400": "#2EFFD1",
    "500": "#00FFC7",
    "600": "#00CC9F",
    "700": "#009978",
    "800": "#006650",
    "900": "#003328",
  },
  fresh: {
    "50": "#E5FFF1",
    "100": "#B8FFD9",
    "200": "#8AFFC0",
    "300": "#5CFFA7",
    "400": "#2EFF8F",
    "500": "#00FF76",
    "600": "#00CC5F",
    "700": "#009947",
    "800": "#00662F",
    "900": "#003318",
  },
  lime: {
    "50": "#FBFFE5",
    "100": "#F4FFB8",
    "200": "#EDFF8A",
    "300": "#E6FF5C",
    "400": "#DFFF2E",
    "500": "#D8FF00",
    "600": "#ADCC00",
    "700": "#829900",
    "800": "#566600",
    "900": "#2B3300",
  },
};
