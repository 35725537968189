import {
  Box,
  Stack,
  Flex,
  Heading,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea,
  HStack,
  useToast,
  VStack,
  Text,
  Button,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Maybe } from "../../../.tina/__generated__/types";
import formSchema, {
  desiredCompletionDateOptions,
  estimatedBudgetOptions,
  hearAboutUsOptions,
  typeOfOrganisationOptions,
} from "../../../consts/formSchema";
import FormInput from "../ContactForm/FormInput";
import { FormRow } from "../ContactForm/Form";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import NatureOfEnquiry from "./NatureOfEnquiry";
import PaginationButtons from "./PaginationButtons";
import FormDropdown from "../ContactForm/FormDropdown";
import countryList from "country-list";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { useCTAStore } from "./store";
import { CheckCircleIcon } from "@chakra-ui/icons";
import axios from "axios";
import { textStyles } from "../../../theme/textStyles";
import useBackgroundColor from "../../../hooks/useBackgroundColor";

interface CTAFormProps {
  data: {
    backgroundPattern?: Maybe<string>;
    headingPage1?: Maybe<string>;
    headingPage2?: Maybe<string>;
    headingPage3?: Maybe<string>;
    headingPage4?: Maybe<string>;
  };
}

function CTAForm({ data: { backgroundPattern, ...headings } }: CTAFormProps) {
  const methods = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });

  const {
    formState: { errors },
    control,
    watch,
    setValue,
    reset,
  } = methods;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const toast = useToast();

  const { page, resetPages } = useCTAStore();

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = async (data) => {
    setIsSubmitting(true);
    await axios.post("/api/forms/contact", data);
    setIsSubmitting(false);
    reset();
    resetPages();
    toast({
      status: "success",
      duration: 9000,
      isClosable: true,
      render: () => (
        <HStack backgroundColor="yellow" padding="15px" rounded="sm">
          <CheckCircleIcon padding="10px" w={10} h={10} />
          <VStack alignItems="flex-start" spacing={0}>
            <Text {...textStyles.mono}>Enquiry Submitted.</Text>
            <Text>We&apos;ll be in touch.</Text>
          </VStack>
        </HStack>
      ),
    });
  };

  const natureOfEnquiry = watch("natureOfEnquiry");
  const careersPressOrOther = ["Careers", "Press", "Other"].includes(
    natureOfEnquiry
  );

  const [disableSubmit, setDisableSubmit] = useState(true);

  const onHCaptchaVerify = (token: string) => {
    setValue("hCaptchaToken", token);
    setDisableSubmit(false);
  };

  const bgColor = useBackgroundColor();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} id="cta-form">
        <Flex
          backgroundImage={`/patterns/${backgroundPattern}.svg`}
          padding={{
            base: "5",
            sm: "8",
            md: "12",
            lg: "20",
            xl: "32",
          }}
          justifyContent="center"
        >
          <Box
            backgroundColor={bgColor}
            paddingX={{
              base: "5",
              sm: "8",
              md: "12",
              lg: "20",
              xl: "32",
            }}
            paddingY={{
              base: "5",
              sm: "8",
              md: "12",
              lg: "20",
              xl: "24",
            }}
            maxW="container.xl"
          >
            <Stack spacing={{ base: "4", sm: "8", md: "10", lg: "14" }}>
              {page === 0 && (
                <Controller
                  control={control}
                  name="natureOfEnquiry"
                  render={({ field: { onChange, value, onBlur } }) => (
                    <NatureOfEnquiry
                      onChange={onChange}
                      value={value}
                      onBlur={onBlur}
                      heading={headings.headingPage1}
                    />
                  )}
                />
              )}

              {page === 1 && (
                <>
                  <Heading
                    textAlign="center"
                    fontSize={{ base: "lg", sm: "xl", md: "2xl", lg: "3xl" }}
                  >
                    {headings.headingPage2}
                  </Heading>
                  <FormRow>
                    <FormInput
                      label="Company Name"
                      name="companyName"
                      isDisabled={natureOfEnquiry === "Careers"}
                    />
                    <FormInput label="Website URL" name="websiteUrl" />
                  </FormRow>
                  <FormRow>
                    <FormDropdown
                      name="desiredCompletionDate"
                      label="Desired Completion Date"
                      options={desiredCompletionDateOptions}
                    />

                    <FormDropdown
                      name="typeOfOrganisation"
                      label="Type of Organisation"
                      options={typeOfOrganisationOptions}
                    />
                  </FormRow>
                </>
              )}

              {page === 2 && (
                <>
                  <Heading
                    textAlign="center"
                    fontSize={{ base: "lg", sm: "xl", md: "2xl", lg: "3xl" }}
                  >
                    {headings.headingPage3}
                  </Heading>
                  <FormRow>
                    <FormInput label="First Name" name="firstName" />
                    <FormInput label="Last Name" name="lastName" />
                  </FormRow>

                  <FormRow>
                    <FormInput label="Phone Number" name="phoneNumber" />
                    <FormInput label="Email Address" name="emailAddress" />
                  </FormRow>

                  <FormRow>
                    <FormInput
                      label="Job Title"
                      name="jobTitle"
                      isDisabled={natureOfEnquiry === "Careers"}
                    />

                    <FormDropdown
                      label="Country"
                      name="country"
                      options={countryList.getNames()}
                    />
                  </FormRow>
                </>
              )}

              {page === 3 && (
                <>
                  <Heading
                    textAlign="center"
                    fontSize={{ base: "lg", sm: "xl", md: "2xl", lg: "3xl" }}
                  >
                    {headings.headingPage4}
                  </Heading>
                  <FormRow>
                    <FormDropdown
                      label="Estimated Budget"
                      name="estimatedBudget"
                      options={
                        natureOfEnquiry === "Blockchain Consulting"
                          ? estimatedBudgetOptions
                          : estimatedBudgetOptions.slice(1)
                      }
                      isDisabled={careersPressOrOther}
                    />

                    <FormDropdown
                      label="How did you find out about us?"
                      name="hearAboutUs"
                      options={hearAboutUsOptions}
                    />
                  </FormRow>

                  <FormControl isRequired id="customMessage">
                    <FormLabel>Custom Message</FormLabel>
                    <Textarea
                      placeholder="Enter your message here"
                      {...methods.register("customMessage")}
                      rounded="none"
                    />
                    <FormErrorMessage>
                      {errors.customMessage?.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormRow>
                    {process.env.NEXT_PUBLIC_HCAPTCHA_SITE_KEY && (
                      <HCaptcha
                        sitekey={process.env.NEXT_PUBLIC_HCAPTCHA_SITE_KEY}
                        onVerify={onHCaptchaVerify}
                      />
                    )}
                  </FormRow>
                </>
              )}

              <PaginationButtons
                submitButton={
                  <Button
                    mt={4}
                    type="submit"
                    variant="outline"
                    borderColor="black"
                    isDisabled={disableSubmit || isSubmitting}
                    isLoading={isSubmitting}
                  >
                    Submit
                  </Button>
                }
              />
            </Stack>
          </Box>
        </Flex>
      </form>
    </FormProvider>
  );
}

export default CTAForm;
