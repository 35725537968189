import dynamic from "next/dynamic";
import { TinaEditProvider } from "tinacms/dist/edit-state";
import { ChakraProvider } from "@chakra-ui/react";
import { TinaCloudCloudinaryMediaStore } from "next-tinacms-cloudinary";

import "../styles/globals.css";
import React, { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import Fonts from "../components/Fonts";
import theme from "../theme";

import { css, Global } from "@emotion/react";
import { LogoJsonLd, NextSeo } from "next-seo";

const TinaCMS = dynamic(() => import("tinacms"), { ssr: false });

const App = ({ Component, pageProps }: any) => {
  // Google Tag Manager is now initialised in /CookiesAcceptance.tsx

  return (
    <>
      <Global
        styles={css`
          body {
            max-width: 100vw;
            overflow-x: hidden;
          }
        `}
      />
      <LogoJsonLd
        logo="https://res.cloudinary.com/labrys/image/upload/v1642484602/labrys-logo-master_2x_s9mebx.png"
        url="https://labrys.io"
      />

      <TinaEditProvider
        editMode={
          <TinaCMS
            clientId={process.env.NEXT_PUBLIC_TINA_CLIENT_ID}
            branch={process.env.NEXT_PUBLIC_EDIT_BRANCH}
            isLocalClient={process.env.NEXT_PUBLIC_USE_LOCAL_CLIENT === "true"}
            mediaStore={TinaCloudCloudinaryMediaStore}
            cmsCallback={(cms) => {
              cms.flags.set("branch-switcher", true);
            }}
            {...pageProps}
          >
            {(livePageProps: any) => (
              <ChakraProvider theme={theme}>
                <NextSeo
                  title={`${
                    livePageProps?.data?.getGlobalDocument?.data?.titlePrefix
                  }${
                    livePageProps?.data?.getGlobalDocument?.data?.titlePrefix &&
                    livePageProps.data?.getPagesDocument?.data?.title &&
                    " - "
                  }${livePageProps.data?.getPagesDocument?.data?.title}`}
                  description={
                    livePageProps.data?.getPagesDocument?.data?.description
                  }
                  openGraph={{
                    url: "https://labrys.io",
                    title: "Labrys",
                    description:
                      livePageProps.data?.getPagesDocument?.data?.description,
                    images: [
                      {
                        url: "https://labrys.group/opengraph.png",
                        width: 800,
                        height: 600,
                        alt: "Labrys Image",
                        type: "image/png",
                      },
                    ],
                  }}
                />

                <Fonts />
                <Layout data={livePageProps.data?.getGlobalDocument?.data}>
                  <Component {...livePageProps} />
                </Layout>
              </ChakraProvider>
            )}
          </TinaCMS>
        }
      >
        <ChakraProvider theme={theme}>
          <NextSeo
            title={`${pageProps?.data?.getGlobalDocument?.data?.titlePrefix}${
              pageProps?.data?.getGlobalDocument?.data?.titlePrefix &&
              pageProps.data?.getPagesDocument?.data?.title &&
              " - "
            }${pageProps.data?.getPagesDocument?.data?.title}`}
            description={pageProps.data?.getPagesDocument?.data?.description}
            openGraph={{
              url: "https://labrys.io",
              title: "Labrys",
              description: pageProps.data?.getPagesDocument?.data?.description,
              images: [
                {
                  url: "https://labrys.group/opengraph.png",
                  width: 800,
                  height: 600,
                  alt: "Labrys Image",
                  type: "image/png",
                },
              ],
            }}
          />

          <Fonts />
          <Layout data={pageProps.data?.getGlobalDocument?.data}>
            <Component {...pageProps} />
          </Layout>
        </ChakraProvider>
      </TinaEditProvider>
    </>
  );
};

export default App;
