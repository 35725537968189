import { Flex, HStack, Spacer, Text } from "@chakra-ui/layout";
import { css } from "@emotion/react";
import React from "react";
import {
  Box,
  Image,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import Link from "next/link";
import { useRouter } from "next/router";
import CollapsingLogo from "./CollapsingLogo";
import MobileMenu from "./MobileMenu";
import { useViewportScroll } from "framer-motion";
import NextImage from "next/image";
import useBackgroundColor from "../../hooks/useBackgroundColor";

interface HeaderProps {
  data: {
    nav: { label: string; href: string }[];
  };
}

/**
 * Renders the logo, and the list of header links.
 *
 * If there is only one link the single item is on the right side of the header.
 * If there are multiple, then they are spread out evenly in the centre of the header.
 */
function Header({ data }: HeaderProps) {
  const router = useRouter();

  const boxShadowCss = useBreakpointValue({
    base: undefined,
    md: css`
      box-shadow: 0px 0px 20px #00000019;
    `,
  });

  const bgColor = useBackgroundColor();
  
  return (
    <>
      <Box display={{ base: "none", lg: "initial" }}>
        <CollapsingLogo />
      </Box>

      <Flex
        display={{ base: "flex", lg: "none" }}
        css={boxShadowCss}
        justifyContent="space-between"
        position="sticky"
        top="0"
        bgColor={bgColor}
        zIndex="4"
      >
        <Link href="/" passHref>
          <NextImage
            src="https://res.cloudinary.com/labrys/image/upload/v1642337566/labrys-logo-master-horizontal_2x_k03tld.png"
            alt="Labrys logo"
            height="50px"
            width="300px"
          />
        </Link>
        <MobileMenu nav={data?.nav} />
      </Flex>

      <Flex
        display={{ base: "none", lg: "flex" }}
        position="sticky"
        top="0"
        height="62px;"
        justifyContent="space-between;"
        zIndex="4"
      >
        <HStack
          width="100%"
          justifyContent={data?.nav.length === 1 ? "right" : "center"}
          bgColor={bgColor}
          boxShadow="0px 0px 20px #00000019;"
        >
          <HStack
            textStyle="mono"
            justifyContent={data?.nav.length === 1 ? "right" : undefined}
            flexGrow={data?.nav.length === 1 ? 1 : undefined}
            spacing={20}
          >
            {data?.nav.map((item, i) => (
              <Link key={`${item.label}-${i}`} href={item.href} passHref>
                <Text
                  marginX="20px"
                  cursor="pointer"
                  textAlign="center"
                  css={css`
                    ${router.asPath.slice(1) === item.href &&
                    css`
                      border-bottom: 2px solid #52e2e1;
                    `}
                  `}
                >
                  {item.label}
                </Text>
              </Link>
            ))}
          </HStack>
        </HStack>
      </Flex>
    </>
  );
}

export default Header;
