import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
} from "@chakra-ui/react";
import React from "react";
import { useFormContext } from "react-hook-form";
import { z } from "zod";

import formSchema from "../../../consts/formSchema";

interface FormDropdownProps {
  label: string;
  name: keyof z.infer<typeof formSchema>;
  options: readonly string[];
  isDisabled?: boolean;
}

const FormDropdown = ({
  label,
  name,
  options,
  isDisabled,
}: FormDropdownProps) => {
  const {
    register,
    formState: { errors, touchedFields },
  } = useFormContext<z.infer<typeof formSchema>>(); // retrieve all hook methods

  const required = isDisabled ? false : !formSchema.shape[name].isOptional();

  return (
    <FormControl
      isRequired={required}
      isInvalid={
        Object.keys(errors).includes(name) &&
        Object.keys(touchedFields).includes(name)
      }
      id={name}
      isDisabled={isDisabled}
    >
      <FormLabel>{label}</FormLabel>
      <Select rounded="none" placeholder="Select an option" {...register(name)}>
        {options.map((option) => (
          <option key={option}>{option}</option>
        ))}
      </Select>
      <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default FormDropdown;
