import { textStyles } from "../textStyles";
import type { SystemStyleObject } from "@chakra-ui/theme-tools";

const baseStyle: SystemStyleObject = {
  ...textStyles.mono,
  fontSize: "16px",
};

export default {
  baseStyle,
};
