import { Box, Flex, HStack, VStack, Text } from "@chakra-ui/layout";
import Image from "next/image";
import Link from "next/link";

// import Link from "next/link";
import React from "react";
import { VscArrowUp } from "react-icons/vsc";
import { textStyles } from "../../theme/textStyles";
import SocialMediaBlock from "./SocialMediaBlock";
// import { VscArrowRight, VscArrowUp } from "react-icons/vsc";
// import { textStyles } from "../../theme/textStyles";

interface FooterProps {
  data: {
    links: { label: string; href: string }[];
  };
}

const Footer = ({ data }: FooterProps) => {
  const date = new Date();
  let year = date.getFullYear();

  return (
    <Flex
      bgColor="yellow"
      alignItems={{ base: "flex-start", lg: "flex-end" }}
      width="100%"
      minH="80px"
      flexDirection={{ base: "column", lg: "row" }}
    >
      {/* <Image
        src="https://res.cloudinary.com/labrys/image/upload/v1642337566/labrys-logo-master-horizontal_2x_k03tld.png"
        alt="Labrys Logo"
        width="923px"
        height="154px"
      /> */}
      <Flex
        direction={{ base: "column", lg: "row" }}
        w="100%"
        justify="space-between"
      >
        <VStack
          align="flex-start"
          pl={{ base: "20px", md: "40px" }}
          fontSize={{ base: "30", lg: "60" }}
          width={{ base: "50%", lg: "fit-content" }}
          fontFamily={textStyles.bold.fontFamily}
          flexGrow={{ base: 1 }}
        >
          {data?.links?.map((item, i) => (
            <Link key={`${item.label}-${i}`} href={item.href}>
              {item.label}
            </Link>
          ))}
        </VStack>
        <Flex
          justify="space-between"
          direction={{ base: "row", lg: "column" }}
          align="flex-end"
          w={{ base: "100%", md: "50%" }}
        >
          <SocialMediaBlock />
          <Box
            bgColor="aqua.500"
            h="114px"
            w="114px"
            onClick={() => window.scrollTo(0, 0)}
            cursor="pointer"
          >
            <VscArrowUp size="100px" />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Footer;
