import { inputAnatomy as parts } from "@chakra-ui/anatomy";
import type { PartsStyleObject } from "@chakra-ui/theme-tools";

const baseStyle: PartsStyleObject<typeof parts> = {
  field: {
    borderColor: "black",
  },
};

export default {
  baseStyle,
};
